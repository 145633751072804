import { useQuery } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { SearchPropertyItem } from '../types/Property'

export const useSearchProperties = (q?: string) => {
  return useQuery<SearchPropertyItem[]>(
    {
      queryKey: ['useSearchProperties', q],
      enabled: !!q,
      queryFn: () => mobileService.get(`/properties/search?q=${q}`).then(item => item.data)
    })
}