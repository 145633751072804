import ReconnectingWebSocket from 'reconnecting-websocket'

export default class Socket {
  private ws: ReconnectingWebSocket | undefined

  public static newInstance() {
    return new Socket()
  }

  public init(WS: string) {
    if (this.ws) {
      this.ws.close()
    }
    this.ws = new ReconnectingWebSocket(WS, [], {
      connectionTimeout: 2000,
      maxRetries: 10,
      reconnectionDelayGrowFactor: 1.3,
    })
  }

  public close() {
    if (this.ws) {
      this.ws.close()
    }
  }

  public on(cb: any) {
    if (!this.ws) {
      return
    }
    this.ws.onmessage = (event) => {
      cb(JSON.parse(event.data))
    }
  }

  public send(data: object) {
    if (!this.ws) {
      return
    }
    this.ws.send(JSON.stringify(data))
  }

  public onConnected(cb: Function) {
    if (!this.ws) {
      return
    }
    this.ws.addEventListener('open', () => {
      cb()
    })
  }

  public onClosed(cb: Function) {
    if (!this.ws) {
      return
    }
    this.ws.onclose = () => {
      cb()
    }
  }
}
