import { createHashHistory, History } from 'history'

const history: History = createHashHistory({})

export const navTo = (location: string = '/', state?: any): void => {
  history.push(location, state)
}

export const goBack = (): void => {
  history.goBack()
}

export default history
