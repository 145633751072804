import { useQuery } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { PropertyItem } from '../types/Property'

export const useGetPropertyDetail = (id: number) => {
  return useQuery<PropertyItem>(
    {
      queryKey: ['useGetPropertyDetail', id],
      enabled: !!id,
      refetchOnWindowFocus: false,
      queryFn: () => mobileService.get(`/properties/${id}/info`).then(item => item.data)
    })
}