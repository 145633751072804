import { Block, Link, List, ListItem, Radio, Sheet, Toolbar } from 'konsta/react'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { LockDispositionStatus } from '../../../types/Property'
import { DISPOSITION_LABELS } from '../../../constants/property'
import { useMutationUpdateDisposition } from '../../../hooks/useMutationUpdateDisposition'
import { GlobalLoading } from '../../../components/GlobalLoading'

const LIST_ITEMS = [
  { title: DISPOSITION_LABELS[LockDispositionStatus.PROPERTY_AVAILABLE], value: LockDispositionStatus.PROPERTY_AVAILABLE },
  { title: DISPOSITION_LABELS[LockDispositionStatus.PROPERTY_STAFF_ACCESS], value: LockDispositionStatus.PROPERTY_STAFF_ACCESS },
  { title: DISPOSITION_LABELS[LockDispositionStatus.PROPERTY_UNDER_MAINTENANCE], value: LockDispositionStatus.PROPERTY_UNDER_MAINTENANCE },
]

export interface UpdateDispositionModelRef {
  open: (status: LockDispositionStatus) => void;
}

interface UpdateDispositionModelProps {
  propertyId: string;
}

export const UpdateDispositionModel = forwardRef<UpdateDispositionModelRef, UpdateDispositionModelProps>((props, ref) => {
  const [sheetOpened, setSheetOpened] = useState(false);
  const [groupValue, setGroupValue] = useState<LockDispositionStatus>();
  const { mutateAsync, isPending } = useMutationUpdateDisposition();

  useImperativeHandle(ref, () => ({
    open: (status: LockDispositionStatus) => {
      setGroupValue(status);
      setSheetOpened(true);
    },
  }), []);

  const handleDone = async () => {
    await mutateAsync({ id: props.propertyId, disposition: String(groupValue) });
    setSheetOpened(false);
  }

  return (
    <Sheet
      className="w-full"
      opened={sheetOpened}
      onBackdropClick={() => setSheetOpened(false)}
    >
      <GlobalLoading loading={isPending} text={isPending ? 'Saving ...' : ''}/>
      <Toolbar top>
        <div className="left" />
        <div className="right">
          <Link toolbar onClick={() => handleDone()}>
            Done
          </Link>
        </div>
      </Toolbar>
      <Block>
        <List>
          {LIST_ITEMS.map((item) => (
            <ListItem
              key={item.title}
              label
              title={item.title}
              media={
                <Radio
                  component="div"
                  value={item.value}
                  checked={groupValue === item.value}
                  onChange={() => setGroupValue(item.value)}
                />
              }
            />
          ))}
        </List>
      </Block>
    </Sheet>
  )
})