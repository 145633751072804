import React, { useEffect, useState } from 'react'
import { Block, Button, Link, List, ListInput, Toast } from 'konsta/react'
import { MdEdit } from 'react-icons/md'
import { DeviceList } from './components/DeviceList'
import { Actions, ConnectStatus } from '../../constants/socket'
import { AddLockDevice } from './components/AddLockDevice'
import { HubSocketProvider, useHubSocketContext } from '../../providers/hubsocket'
import { FabActions } from './components/FabActions'
import { BroadcastMessagePopup } from './components/BroadcastMessagePopup'
import { ExcludeDevice } from './components/ExcludeDevice'
import { ZwaveReset } from './components/ZwaveReset'
import { ILayout } from '../../components/ILayout'
import { useGetPropertyHubServers } from '../../hooks/useGetPropertyHubServers'
import { Controller, useForm } from 'react-hook-form'
import { GlobalLoading } from '../../components/GlobalLoading'
import { queryClient } from '../../utils/reactQuery'
import { useAppNNConfig } from '../../store/appNNConfig'

const HomeContainer = () => {
  const { deviceId, setAppConfig } = useAppNNConfig()
  const { data: hubServer, isFetching, failureReason, refetch } = useGetPropertyHubServers(deviceId)
  const [showBroadcastMessage, setShowBroadcastMessage] = useState<boolean>(false)
  const { control, setValue, handleSubmit, trigger } = useForm({
    values: {
      deviceId,
    }
  }) // Initialize useForm
  const {
    init,
    disconnect,
    connectStatus,
    broadcastMessages,
    action,
    sendAction,
    onBroadcast,
    isDeviceLoading,
    loadDevices,
    devices
  } = useHubSocketContext()

  useEffect(() => {
    if (hubServer) {
      init(hubServer)
    }
  }, [hubServer])

  useEffect(() => {
    if (connectStatus == ConnectStatus.Connected) {
      loadDevices()
    }
  }, [connectStatus])

  useEffect(() => {
    // Reload device when broadcast update
    const off = onBroadcast((data: any) => {
      if (['hub.device.added', 'hub.device.removed'].includes(data.data.msg_subclass)) {
        loadDevices()
      }
    })
    return () => off && off()
  }, [])

  const submitProperty = (data: any) => {
    if (data.deviceId === deviceId) {
      queryClient.invalidateQueries({
        queryKey: ['useGetPropertyHubServers']
      })
      return;
    }
    setAppConfig({ deviceId: data.deviceId })
  }

  return (
    <ILayout>
      <GlobalLoading loading={isFetching} text={'Checking property'} />
      {(!deviceId || !hubServer) ? (
        <Block>
          <form onSubmit={handleSubmit(submitProperty)}>
            <p className="text-base font-bold text-gray-800 text-center">Enter Property ID to access</p>
            <List strong inset className="my-2">
              <Controller
                control={control}
                name="deviceId"
                render={({ field }) => (
                  <ListInput
                    label="Property ID"
                    type="text"
                    placeholder="Enter Property ID"
                    {...field}
                  />
                )}
              />
              <p className="text-red-500 p-2 pl-3 text-center">{failureReason?.message}</p>
            </List>
            <Button large>
              Submit
            </Button>
          </form>
        </Block>
      ) : (
        <Block className="text-gray-800 flex justify-between">
          <p>Property ID <span className="text-primary">#{deviceId}</span></p>
          <p className="flex gap-2"><Link onClick={() => {
            disconnect()
            setAppConfig({ deviceId: undefined })
          }}><MdEdit /> Change</Link></p>
        </Block>
      )}
      {connectStatus === ConnectStatus.Connecting && (
        <Block>
          <p className="text-gray-800">
            Connecting to Hub <span className="text-primary">#{hubServer?.hubId}</span> ...
          </p>
        </Block>
      )}
      {connectStatus === ConnectStatus.Failed && (
        <Block>
          <p className='text-red-800'>The hub is currently offline or experiencing network issues</p>
          <br />
          <div className="flex flex-col gap-3">
            <Button onClick={() => hubServer && init(hubServer)} large>
              Retry
            </Button>
          </div>
        </Block>
      )}
      {connectStatus === ConnectStatus.Connected && (
        <>
          <Block>
            <p>Connected to Hub <span className="text-primary">#{hubServer?.hubId}</span></p>
          </Block>
          {devices && <DeviceList devices={devices} />}
          <Block className="space-y-4">
            <Button disabled={isDeviceLoading} onClick={() => loadDevices()} large>
              {isDeviceLoading ? 'Loading...' : 'Refresh'}
            </Button>
          </Block>
          <Toast
            position='center'
            className="bottom-12.5-safe"
            opened={broadcastMessages.length > 0 && !showBroadcastMessage}
            button={
              <Button rounded clear small inline onClick={() => setShowBroadcastMessage(true)}>
                More
              </Button>
            }
          >
            <div className='shrink'>{broadcastMessages[0]?.message}</div>
          </Toast>
          {action === Actions.ADD_LOCK && (
            <AddLockDevice open={action === Actions.ADD_LOCK} close={() => sendAction(undefined)} />
          )}
          <ZwaveReset
            open={!!action && [Actions.ZWAVE_RESET, Actions.HUB_REBOOT].includes(action)}
            close={() => sendAction(undefined)}
          />
          <ExcludeDevice open={action === Actions.EXCLUDE_LOCK} close={() => sendAction(undefined)} />
          <BroadcastMessagePopup
            popupOpened={showBroadcastMessage}
            messages={broadcastMessages}
            setPopupOpened={setShowBroadcastMessage}
          />
          <FabActions />
        </>
      )}
    </ILayout>
  )
}

export const HubManage = () => (
  <HubSocketProvider>
    <HomeContainer />
  </HubSocketProvider>
)
