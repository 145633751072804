import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { clear, getItem } from './storage'
import { onError } from '@apollo/client/link/error'
import { navTo } from '../browserHistory'
import { AUTH_TOKEN } from '../constants/auth'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_GRAPH_QL
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItem(AUTH_TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-auth-token': token
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('Err')
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )

  if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`)
  if (networkError && (networkError as any).statusCode === 401) {
    clear()
    navTo('/login-hub')
  }
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    addTypename: false
  })
})
