import React, { createContext, useContext, useState } from 'react';
import ErrorDialog from './IErrorDialog'

interface IErrorDialogContext {
  showErrorDialog: (message: string) => void;
  hideErrorDialog: (message: string) => void;
}

// Create a context for managing the error dialog state
const ErrorDialogContext = createContext<IErrorDialogContext>({
  showErrorDialog: () => {},
  hideErrorDialog: () => {},
});

export const useErrorDialog = () => useContext(ErrorDialogContext);

interface IErrorDialogProviderProps {
  children: React.ReactNode;
}

export const IErrorDialogProvider = ({ children }: IErrorDialogProviderProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);

  // Function to show the error dialog with a message
  const showErrorDialog = (message: string) => {
    setErrorMessage(message);
    setDialogVisible(true);
  };

  // Function to hide the error dialog
  const hideErrorDialog = () => {
    setDialogVisible(false);
    setErrorMessage('');
  };

  return (
    <ErrorDialogContext.Provider value={{ showErrorDialog, hideErrorDialog }}>
      {children}
      {isDialogVisible && <ErrorDialog message={errorMessage} onClose={hideErrorDialog} />}
    </ErrorDialogContext.Provider>
  );
};
