import { useMutation } from '@tanstack/react-query'
import { EzloMss } from '../services/ezloMss'

export const useMutationLoginEzlo = () => {
  return useMutation(
    {
      mutationFn: ({ username, password }: { username: string, password: string }) => {
        return EzloMss.getInstance().login(username, password);
      },
    }
  )
}