import React from 'react'

import { useParams } from 'react-router'
import { useGetPropertyDetail } from '../../hooks/useGetPropertyDetail'
import { GlobalLoading } from '../../components/GlobalLoading'
import { SubmitSerialNumber } from './components/SubmitSerialNumber'
import { GetAccessCode } from './components/GetAccessCode'
import { Link, Navbar } from 'konsta/react'
import { MdKeyboardArrowLeft, MdRefresh } from 'react-icons/md'
import { goBack } from '../../browserHistory'
import ShareLocation from '../../components/ShareLocation/ShareLocation'
import { queryClient } from '../../utils/reactQuery'

enum Steps {
  ShareLocation = 0,
  SubmitSerialNumber = 1,
  GetAccessCode = 2
}

export const PropertyActivate = () => {
  const params = useParams<{ propertyId: string }>();

  const { data: item, isLoading, isFetched } = useGetPropertyDetail(Number(params.propertyId));
  const [step, setStep] = React.useState(Steps.ShareLocation);

  const refreshAccessCode = () => {
    queryClient.invalidateQueries({
      queryKey: ['usePropertyAccessCode'],
    })
  }

  return (
    <>
      <GlobalLoading loading={isLoading} />
      <Navbar
        title='Activate Property'
        left={
          <Link className='text-sm' navbar onClick={() => goBack()}>
            <MdKeyboardArrowLeft size='30' />
          </Link>
        }
        right={
          Steps.GetAccessCode === step ? <Link className='text-sm' navbar onClick={() => refreshAccessCode()}>
            <MdRefresh size='30' />
          </Link> : null
        }
      />
      {isFetched && <>
        {step === Steps.ShareLocation && <ShareLocation onShareLocation={() => setStep(Steps.SubmitSerialNumber)}/>}
        {step === Steps.SubmitSerialNumber && <SubmitSerialNumber nextStep={() => setStep(Steps.GetAccessCode)}  propertyId={Number(params.propertyId)} />}
        {step === Steps.GetAccessCode && <GetAccessCode propertyId={Number(params.propertyId)} />}
      </>}
    </>
  )
}

