import { useMutation } from '@tanstack/react-query'
import { login } from '../services/mobile.service'

export const useMutationLoginNternow = () => {
  return useMutation(
    {
      mutationFn: ({ username, password }: { username: string, password: string }) => {
        return login(username, password);
      },
    }
  )
}