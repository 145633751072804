import { Redirect, RouteProps } from 'react-router'
import { useAppConfig } from '../store/appConfig'
import { Route } from 'react-router-dom'
import React from 'react'
import { useAppNNConfig } from '../store/appNNConfig'

export const PrivateHubRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { loginType } = useAppConfig();

  return (
    <Route
      {...rest}
      render={(props) => {
        // If authenticated, render the protected component
        if (loginType) {
          return Component ? <Component {...props} /> : null;
        }
        // If not authenticated, redirect to login
        return <Redirect to="/login-hub" />;
      }}
    />
  );
};

export const PrivateNNRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { token, setAppConfig } = useAppNNConfig();

  return (
    <Route
      {...rest}
      render={(props) => {
        // If authenticated, render the protected component
        if (token) {
          return Component ? <Component {...props} /> : null;
        }
        setAppConfig({
          redirectUrl: props.location.pathname,
        })
        // If not authenticated, redirect to login
        return <Redirect to="/login-nn" />;
      }}
    />
  );
};
