import { isMobile } from 'react-device-detect';
import { Panel, Popup } from 'konsta/react'
import { Props as PopupProps } from 'konsta/react/types/Popup'
import { Props as PanelProps } from 'konsta/react/types/Panel'

type PopupCustomProps = PopupProps | PanelProps

export const PopupCustom = (props: PopupCustomProps) => {
  // return isMobile ? <Panel {...props}></Panel> : <Popup {...props}></Popup>;
  return <Popup {...props}></Popup>;
}