import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Auth } from '../pages/Auth'
import { LoginToNN } from '../pages/LoginToNN'
import { LoginEzlo } from '../pages/LoginEzlo'
import { Home } from '../pages/Home'
import { HubManage } from '../pages/HubManage'
import { HubConnect } from '../pages/HubConnect'
import { HubServers } from '../pages/HubServers'
import { PropertyManage } from '../pages/PropertyManage'
import { PropertyDetail } from '../pages/PropertyDetail'
import { PropertyActivate } from '../pages/PropertyActivate'
import { ActivateProperty } from '../pages/ActivateProperty'
import { ActivateLock } from '../pages/ActivateLock'
import { LockActivate } from '../pages/LockActivate'
import NotFound from '../pages/NotFound'
import { PrivateHubRoute, PrivateNNRoute } from './PrivateRoute'


type Props = {}
const Routes: React.FC<Props> = () => (
  <Switch>

    {/* Public route */}
    <Route exact path='/login' component={Auth} />
    <Route exact path='/login-nn' component={LoginToNN} />
    <Route exact path='/login-hub' component={LoginEzlo} />
    <Route exact path='/' component={Home} />

    {/* Nternow Route */}
    <PrivateNNRoute exact path='/hub-manage' component={HubManage} />
    <PrivateNNRoute exact path='/property-manage' component={PropertyManage} />
    <PrivateNNRoute exact path='/properties/:propertyId' component={PropertyDetail} />
    <PrivateNNRoute exact path='/properties/:propertyId/activate-property' component={PropertyActivate} />
    <PrivateNNRoute exact path='/properties/:propertyId/activate-lock' component={LockActivate} />
    <PrivateNNRoute exact path='/activate-lock' component={ActivateLock} />
    <PrivateNNRoute exact path='/activate-property' component={ActivateProperty} />

    {/* Hub routes */}
    <PrivateHubRoute exact path='/hub-connect' component={HubConnect} />
    <PrivateHubRoute exact path='/servers' component={HubServers} />
    {/*<Redirect exact from="/" to="/home" />*/}
    <Route component={NotFound} />
  </Switch>
)

export default Routes
