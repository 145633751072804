import React, { useState } from 'react';
import { Block, Button, Link, List, ListInput } from 'konsta/react';
import NternowLogo from '../../assets/logo-dark.svg';
import { LoginType, useAppConfig } from '../../store/appConfig';
import { goBack, navTo } from '../../browserHistory'
import { useForm, Controller } from 'react-hook-form';
import { MdArrowBack } from 'react-icons/md';
import { HELP_CONTACT } from '../../constants/common'
import { useMutationLoginEzlo } from '../../hooks/useMutationLoginEzlo'

export function LoginEzlo() {
  const { setAppConfig } = useAppConfig();
  const [errorMsg, setErrorMsg] = useState<string>();
  const { mutateAsync, isPending } = useMutationLoginEzlo();
  const { control, handleSubmit } = useForm(); // Initialize useForm

  const loginAction = (data: any) => {
    setErrorMsg('');
    const { username, passwordX } = data;

    mutateAsync({
      username, password: passwordX
    })
      .then((item) => {
        setAppConfig({
          token: item.data.token,
          loginType: LoginType.client,
          hubAccountId: username
        });
        navTo('/servers');
      })
      .catch((e) => {
        setErrorMsg('Wrong username or password');
      });
  };

  const handleHelp = () => {
    window.open(HELP_CONTACT, '_self'); // Open help page in new tab
  };

  return (
    <Block className='justify-center items-center max-w-sm m-auto mt-32'>
      <NternowLogo className='text-center w-48 m-auto mb-5' />
      <p className='text-primary text-xl font-bold text-center m-auto'>Login with Hub Credentials to manage hub & devices directly.</p>
      <form autoComplete="off" onSubmit={handleSubmit(loginAction)}>
        <List insetIos>
          <Controller
            control={control}
            name="username"
            render={({ field }) => (
              <ListInput
                label="Username"
                type="text"
                required
                autoComplete={'off'}
                placeholder="Username"
                {...field} // Spread the field props
              />
            )}
          />
          <Controller
            control={control}
            name="passwordX"
            render={({ field }) => (
              <ListInput
                label="Password"
                type="password"
                required
                autoComplete={'new-password'}
                placeholder="Your password"
                {...field} // Spread the field props
              />
            )}
          />
          {/*  show error here */}
          {!isPending && <label className="m-4 pt-2 text-red-500 text-sm">{errorMsg}</label>}
        </List>

        <Button disabled={isPending} large className="!bg-green-500">
          {!isPending ? 'Login to Manage Hub' : 'Logging in...'}
        </Button>

        {/* Back and Help links */}
        <div className="flex justify-between gap-2 text-center mt-5">
          <Link onClick={() => goBack()}>
            <MdArrowBack /> Back to home
          </Link>
          <Link onClick={handleHelp}>Need Help?</Link> {/* Help link added */}
        </div>
      </form>
    </Block>
  );
}
