import { useMutation } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { queryClient } from '../utils/reactQuery'

export const useMutationUpdateDisposition = () => {
  return useMutation(
    {
      mutationFn: ({ id, disposition }: { id: string, disposition: string }) => {
        return mobileService.patch(`/properties/${id}/disposition`, {
          disposition,
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['useGetPropertyDetail']
        })
      }
    }
  )
}