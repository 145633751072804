import { useQuery } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { PropertyCommunity } from '../types/Property'

export const useGetCommunities = () => {
  return useQuery<PropertyCommunity[]>(
    {
      queryKey: ['useGetCommunities'],
      queryFn: () => mobileService.get('/properties/communities').then(item => item.data)
    })
}