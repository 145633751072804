import { useQuery } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { PropertyAccessCode } from '../types/Property'

export const usePropertyAccessCode = (id: number) => {
  return useQuery<PropertyAccessCode>(
    {
      queryKey: ['usePropertyAccessCode', id],
      enabled: !!id,
      retry: 0,
      refetchOnWindowFocus: false,
      queryFn: () => mobileService.get(`/properties/${id}/code`).then(item => item.data)
    })
}