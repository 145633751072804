import { DomainStatus } from './Status'
import { Catalog, PartialLock } from './Customer'
import Community from './Community'
import { AgentsProperties, UserType, UserType as User } from './User'
import { CustomerVisit } from './Visit'
import { TimezoneCode } from '../utils/helpers'

export type BaseProperty = {
  property_id: number
  address_1: string
  builder: string
  state?: string
  builder_id?: number
  timezone: TimezoneCode | Catalog
  subdivision: string
  house_num: string
  lock_serial_number: string
  grouping?: string
  propertyStatusId?: string
  url_1?: string
  lock?: PartialLock | Lock
  client?: {
    requires_opt_in?: boolean
    name?: string
  }
  timezoneCatalog?: {
    catalog_id?: TimezoneCode
    value?: string
  }
  agents?: Array<{
    user: UserType
    agent_username?: string
    is_project_manager: boolean
  }>
  hub?: {
    serial_number: string
    hub_type: string
  }
  sunParams?: SunParams
  adjustableTourHours: number
  tourHoursStart?: number
  tourHoursEnd?: number
  propertyTourHours?: {
    dayOfWeek: number
    tourHoursStart: string
    tourHoursEnd: string
    isToursAvailable: string
  }
  propertyCommunity?: {
    communities?: Community
  }
  alarm?: boolean
  alarm_company?: string | null
  armed_hours?: string | null
  disarm_code?: string | null
  emergency_notes?: string | null
  marketing_name?: string | null
  novihome_integration?: string | null
  nternow_hub_backup_code?: string | null
  property_backup_code?: string | null
}

export type SunParams = {
  sunrise: string
  sunset: string
  untilSunrise: string
  untilSunset: string
  isBeforeSunrise: boolean
  isDuringDaytime: boolean
  isAfterSunset: boolean
}
export interface BaseLock {
  serial_number: string
  status?: DomainStatus
  lockStatusId?: string
  battery_change: Date
  comments: string
  version: string
  purchase: Date
  additional_info_1: string
  manufacturer: string
  licensee: string
  license_start_date: Date
  created_on: string
  created_by: string
  modified_on: string
  modified_by: string
}
export interface Lock extends BaseLock {
  property?: Property
  lockStatus?: string
  year: number
  batch: string
  hardware_version: string
  software_version: string
  client?: { builder_id?: number; name?: string }
}

export interface Shipments {
  shipments: Array<Shipment>
}

export interface BaseShipment {
  lockSerialNumber?: string[]
  cost: number
  qty?: number
  carrier?: string
  date?: Date
  tracking_number?: string
  builder_id?: number
  username?: string
  status_id?: string
  number?: number
  replacement_lock_number?: string
  requested_by?: string
  notes?: string
}

export interface PaginatedShipment extends BaseShipment {
  id: number
}

export interface Shipment extends BaseShipment {
  id?: number
}

export interface Property extends BaseProperty {
  lock_assigned: string
  lock_terminated: string | null
  address_2: number
  client?: {
    name: string
    requires_opt_in?: boolean
  }
  propertyCommunity?: { communities: Community }
  disposition?: string
  propertyStatusId?: string
  community?: Community
  lock: Lock
  visits: CustomerVisit[]
  availableAgents: User[]
  assignedAgents: AgentsProperties[]
  status?: {
    status_id?: string
  }
  hub?: {
    serial_number: string
    hub_type: string
  }
  hs?: string
  state: string
  city: string
  remarks?: string
  url_1: string
  zipcode: string
  latitude: number
  builder_id: number
  longitude: number
  house_num: string
  modified_on: Date
  modified_by?: string
  LASSO_project_id: string | null
  contract_date?: Date
  price: number
  bedrooms: number
  halfBathrooms: number
  fullBathrooms: number
  squareFootage: number
  isModel: boolean
  community_id: number
  alarm: boolean
  alarm_company: string | null
  armed_hours: string | null
  disarm_code: string | null
  emergency_notes: string | null
  marketing_name?: string | null
  novihome_integration?: string | null
  nternow_hub_backup_code?: string | null
  property_backup_code?: string | null
}

export interface SearchPropertyItem {
  property_id: number
  lotNumber: string
  address: string
}

export interface PropertyItemShortInfo extends SearchPropertyItem {
  latitude: number,
  longitude: number,
}

export interface PropertyItemGeo extends PropertyItemShortInfo {
  distance: number
}

export enum LockDispositionStatus {
  PROPERTY_AVAILABLE = 'PROPERTY_AVAILABLE',
  PROPERTY_STAFF_ACCESS = 'PROPERTY_STAFF_ACCESS',
  PROPERTY_UNDER_MAINTENANCE = 'PROPERTY_UNDER_MAINTENANCE',
  PROPERTY_NOT_AVAILABLE = 'PROPERTY_NOT_AVAILABLE',
}

export interface PropertyItem {
  lotNumber: string
  address: string
  price: number
  latitude: number
  longitude: number
  beds: number
  baths: number
  hBaths: number
  sqft: number
  tourHoursStart: string
  tourHoursEnd: string
  builder: string
  builder_id: number
  marketingName: string
  smart_lock_serial_number: string
  community_id: number
  propStatus: LockDispositionStatus
  propStatusDescription: string
  lockStatus: string
  lockStatusDescription: string
  smartLockStatus: string
  hub_serial_number: string
  communityName: string
  images: string[]
}

export interface PropertyCommunity {
  name: string
  community_id: number
  properties: number[]
}

export interface PropertyAccessCode {
  code: string;
  isUnlocking: boolean;
}