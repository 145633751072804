import { useEffect } from 'react'
import { Block, Button } from 'konsta/react'
import { useGeoLocation } from '../../hooks/useGeoLocation'
import { FaMapLocationDot, FaPhone } from "react-icons/fa6";
import { MdOutlineLocationOff } from "react-icons/md";

interface ShareLocationProps {
  onShareLocation: () => void
}

const ShareLocation = (props: ShareLocationProps) => {
  const { requestLocation, error, location, loading } = useGeoLocation();

  const onAccept = (event: any) => {
    event.preventDefault();
    requestLocation();
  };

  useEffect(() => {
    if (location) {
      props.onShareLocation();
    }
  }, [location])

  return (
    <Block>
      <div className="flex flex-col justify-center text-center gap-3">
        {error ? (
          <>
            <MdOutlineLocationOff className="m-auto text-red-800" size={100}/>
            <h1 className="text-base">Uh-Oh</h1>
            <div className="error-message-container">
              <p className='m-0'>
                It appears that you failed to turn on location services.
                Location services need to be turned on to save the property location and build the geofence for NterNow activation
              </p>
            </div>

            <Block className='troubleshoot-points'>
              <h4 className='text-red-800 text-base mt-4 mb-3'>Troubleshooting Steps</h4>
              <ol className="list-decimal text-left">
                <li>Make sure to turn on location sharing in your browser. Here's a helpful resource: <a className="text-primary" href="https://nternow.freshdesk.com/en/support/solutions/articles/63000280201-how-to-enable-location-services" rel="noreferrer"  target='_blank'>link</a></li>
                <li>Make sure you are near or close to the property</li>
                <li>Call live support if in proper location and still receiving error</li>
              </ol>
              <a href="tel:+1-844-531-1283" className="flex justify-center items-center text-primary text-sm gap-1 mt-3">
                Need Help? <FaPhone />
              </a>
            </Block>
          </>
        ) : (
          <>
            <div className="m-auto"><FaMapLocationDot size={100}/></div>
            <h1 className="h2">Please share your location</h1>
            <p>
              Location services need to be turned on to save the property location and build the geofence for NterNow activation
            </p>
          </>
        )}
        <div className="box-footer mt-auto">
          <Button large onClick={onAccept} className="button w-100">
            {loading ? 'Getting location ...' : 'Continue'}
          </Button>
        </div>
      </div>
    </Block>
  );
};

export default ShareLocation;
