import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import * as storage from './storage'
import appConfig from '../config'
import { useAppNNConfig } from '../store/appNNConfig'

// Default headers without the token
export const defaultHeaders = {
  'Content-Type': 'application/json'
}

const config: AxiosRequestConfig = {
  headers: defaultHeaders,
  baseURL: appConfig.MOBILE_API
}

// Create Axios instance
export const mobileService = axios.create(config)

// Interceptor to dynamically set `x-auth-token`
mobileService.interceptors.request.use(
  (config) => {
    // Retrieve the token from storage or state
    const token = useAppNNConfig.getState().token;

    // If the token exists, set it in the headers
    if (token) {
      config.headers.set('x-auth-token', token)
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

// Response interceptors for handling responses and errors
const onFulfilled = (response: AxiosResponse): AxiosResponse => response
const onRejected = (error: AxiosError): Promise<AxiosError> => {
  const codes: Array<number> = [401]
  if (error.response && codes.includes(error.response.status)) {
    storage.clear() // Clear storage on unauthorized access
    window.location.reload() // Reload the page to force re-authentication
  }
  const message = error.response?.data;
  return typeof message === 'string' ? Promise.reject(new Error(message)) : Promise.reject(error)
}

// Use response interceptors
mobileService.interceptors.response.use(onFulfilled, onRejected)

// Example login function
export const login = (username: string, password: string) => {
  return mobileService.post('auth/users', {
    username,
    password,
    platform: 'ios'
  })
}
