import { List, ListItem } from 'konsta/react'
import { MdOutlineLogout, MdOutlinePhone, MdOutlineQuestionAnswer } from 'react-icons/md'
import React from 'react'
import { HELP_CONTACT } from '../constants/common'

interface MenuHelpProps {
  logout: () => void
}

export const MenuHelp = ({ logout }: MenuHelpProps) => {

  return (
    <List strongIos outlineIos>
      <ListItem
        titleWrapClassName="py-1.5"
        title={
          <p className='flex items-center text-sm'>
            <MdOutlinePhone className='w-4 h-4 mr-1'></MdOutlinePhone>Live Help
          </p>
        }
        href={HELP_CONTACT}
        link
      />
      <ListItem
        titleWrapClassName="py-1.5"
        onClick={() => {
          window.open(
            'https://nternow.freshdesk.com/support/solutions/articles/63000263650-smart-home-connections-faq',
            '_blank'
          )
        }}
        title={
          <p className='flex items-center text-xs'>
            <MdOutlineQuestionAnswer className='w-4 h-4 mr-1'></MdOutlineQuestionAnswer>FAQ
          </p>
        }
        link
      />
    </List>
  )
}