import React, { useState } from 'react'
import { Block, Button, List, ListItem, Navbar, Searchbar } from 'konsta/react'
import { useSearchProperties } from '../../hooks/useSearchProperties'
import { Controller, useForm } from 'react-hook-form'
import { MdNumbers, MdOutlineHome, MdOutlinePlace } from 'react-icons/md'
import { ILayout } from '../../components/ILayout'

export const PropertyManage = () => {
  const [search, setSearch] = useState<string>('');
  const { control, setValue, handleSubmit, trigger } = useForm({
    values: {
      search: '',
    }
  }) // Initialize useForm
  const { data: items, isLoading, isFetched } = useSearchProperties(search);

  const handleSearch = async (data: any) => {
    setSearch(data.search)
  }

  return (
    <ILayout>
      <Block className="mb-safe">
        <div className="text-center">
          <h1 className='text-lg'>Search for property you want to manage.</h1>
        </div>
        <form autoComplete='off' onSubmit={handleSubmit(handleSearch)}>
          <List>
            <Controller
              control={control}
              name='search'
              render={({ field }) => (
                <Searchbar placeholder='Propery ID, Lot or Address' {...field} onClear={() => setValue('search', '')}/>
              )}
            />
          </List>
          <Button
            large
            disabled={isLoading}
            className='text-white text-sm font-bold py-2 px-2 rounded'
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </Button>
        </form>
        <List>
          {items?.map((item) => (
            <ListItem href={`/#/properties/${item.property_id}`} title={
              <>
                <div className="flex gap-1 flex-col">
                  <p className="flex gap-2 items-center text-primary"><MdOutlineHome size={20} />
                    <span>{item.property_id}</span></p>
                  <p className="flex gap-2 items-center"><MdNumbers size={20}/>Lot no. {item.lotNumber}</p>
                  <p className="flex gap-2 items-center"><MdOutlinePlace size={20} /> {item.address}</p>
                </div>
              </>
            } key={item.property_id} link>
            </ListItem>
          ))}
        </List>
        {isFetched && items?.length === 0 && <Block className='text-center'>No data found</Block>}
      </Block>
    </ILayout>
  )
}

