import React from 'react'
import { NNDrawer } from './NNDrawer'

export interface ILayoutProps {
  children?: React.ReactNode;
  title?: string;
  isHome?: boolean;
}

export const ILayout = (prop: ILayoutProps) => {
  return (
    <>
      <NNDrawer title={prop.title} isHome={prop.isHome}/>
      {prop.children}
    </>
  )
}