import { useQuery } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { PropertyItemShortInfo } from '../types/Property'

export const useGetListProperties = (ids: number[]) => {
  return useQuery<PropertyItemShortInfo[]>(
    {
      queryKey: ['useGetListProperties', ids],
      enabled: !!ids.length,
      queryFn: () => mobileService.get(`/properties/list-properties?property_ids=${ids.join(',')}`).then(item => item.data)
    })
}