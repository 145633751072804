import { useMutation } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { queryClient } from '../utils/reactQuery'

export const useRetireLock = () => {
  return useMutation(
    {
      mutationFn: ({ id, isSold }: { id: string, isSold: boolean }) => {
        return mobileService.patch(`/properties/${id}/retire?sold=${isSold ? 1: 0}`)
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['useGetPropertyDetail']
        })
      }
    }
  )
}