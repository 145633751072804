import { MdNumbers, MdOutlineHome, MdOutlinePlace } from 'react-icons/md'
import { ListItem } from 'konsta/react'
import React from 'react'
import { PropertyItemGeo } from '../../../types/Property'

interface Props {
  property: PropertyItemGeo;
  activate?: 'lock' | 'property';
}

export const PropertyItem = ({ property, activate }: Props) => {
  return (
    <ListItem href={`/#/properties/${property.property_id}/activate-${activate}`} title={
      <div className="flex gap-1 flex-col">
        <p className="flex gap-2 items-center text-primary"><MdOutlineHome size={20} />
          <span>{property.property_id}</span></p>
        <p className="flex gap-2 items-center"><MdNumbers size={20} />Lot no. {property?.lotNumber}</p>
        <p className="flex gap-2 items-center"><MdOutlinePlace size={20} /> {property?.address}</p>
      </div>
    } key={property.property_id} link>
    </ListItem>
  )
}