import React from 'react';
import { MdHelp } from 'react-icons/md';
import { Dialog, DialogButton } from 'konsta/react'
import { HELP_CONTACT } from '../../constants/common'

interface IErrorDialogProps {
  message: string;
  onClose: () => void;
}

const ErrorDialog = ({ message, onClose }: IErrorDialogProps) => (
  <Dialog opened={true} content={message} onBackdropClick={onClose} buttons={
    <>
      <DialogButton onClick={onClose}>
        Close
      </DialogButton>
      <DialogButton className="flex gap-1" onClick={() => {
        window.open(HELP_CONTACT, '_self');
      }}>
        <MdHelp /> Help
      </DialogButton>
    </>
  } />
);

export default ErrorDialog;
