import { create } from 'zustand'

export type AppShareStoreData = {
  deviceId?: string;
}

type AppConfigMethod = {
  setAppShareConfig: (data: Partial<AppShareStoreData>) => void
  resetAppShareConfig: () => void
}

type AppConfig = AppShareStoreData & AppConfigMethod

export const appShareStore = create<AppConfig>((set) => ({
  setAppShareConfig: (data) => {
    set((state) => ({ ...state, ...data }))
  },
  resetAppShareConfig: () => {
    set((state) => ({
      deviceId: undefined
    }))
  },
}));
