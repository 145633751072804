import React, { useEffect, useRef } from 'react'
import {
  Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
  Block,
  Button,
  Dialog,
  DialogButton,
  Link,
  Navbar
} from 'konsta/react'
import { useParams } from 'react-router'
import { useGetPropertyDetail } from '../../hooks/useGetPropertyDetail'
import { MdEdit, MdKeyboardArrowLeft } from 'react-icons/md'
import { useRetireLock } from '../../hooks/useRetireLock'
import { goBack, navTo } from '../../browserHistory'
import { GlobalLoading } from '../../components/GlobalLoading'
import { DISPOSITION_LABELS } from '../../constants/property'
import { LockDispositionStatus } from '../../types/Property'
import { UpdateDispositionModel, UpdateDispositionModelRef } from './components/UpdateDispositionModel'

export const PropertyDetail = () => {
  const params = useParams<{ propertyId: string }>();

  const [actionsTwoOpened, setActionsTwoOpened] = React.useState(false);
  const [alertOpened, setAlertOpened] = React.useState(false);
  const { data: item, isLoading, isFetched } = useGetPropertyDetail(Number(params.propertyId));
  const { mutateAsync: retireLock, isPending } = useRetireLock();
  const refModel = useRef<UpdateDispositionModelRef>(null);

  const handleUnpair = async (isSold: boolean = false) => {
    setActionsTwoOpened(false);
    await retireLock({ id: params.propertyId, isSold });
    navTo('/property-manage');
  }

  useEffect(() => {
    if (isFetched && item?.propStatus === LockDispositionStatus.PROPERTY_NOT_AVAILABLE) {
      setAlertOpened(true);
    }
  }, [isFetched])

  return (
    <>
      <GlobalLoading loading={isLoading || isPending} text={isPending ? 'retiring ...' : ''}/>
      <Navbar
        title='Property detail'
        left={
          <Link className='text-sm' navbar onClick={() => goBack()}>
            <MdKeyboardArrowLeft size='30' />
          </Link>
        }
      />
      {isFetched && item && item?.propStatus !== LockDispositionStatus.PROPERTY_NOT_AVAILABLE && <Block className="mb-safe">
        <div className="container">
          <div className="flex justify-between border-b-2 pb-3">
            <h1 className='text-xl font-bold'>Property Disposition</h1>
            <Link onClick={() => refModel.current?.open(item.propStatus)}><MdEdit size={14} />{DISPOSITION_LABELS[item.propStatus]}</Link>
          </div>
          <h1 className='text-lg m-2'>Mark this home as Sold and remove hub device from the property</h1>
          <Button large className="mt-3" onClick={() => setActionsTwoOpened(true)}>
            Unpair
          </Button>
        </div>
        <Actions
          opened={actionsTwoOpened}
          onBackdropClick={() => setActionsTwoOpened(false)}
        >
          <ActionsGroup>
            <ActionsLabel className="text-center">
              You are going to retire this lock this lock. Do you want to mark this property as SOLD
            </ActionsLabel>
            <ActionsButton onClick={() => handleUnpair(true)} bold>
              Yes, mark it SOLD
            </ActionsButton>
            <ActionsButton color="blue" colors={
              { textIos: 'text-black' }
            } onClick={() => handleUnpair(false)}>
              No, it is NOT SOLD
            </ActionsButton>
          </ActionsGroup>
          <ActionsGroup>
            <ActionsButton onClick={() => setActionsTwoOpened(false)}>
              Cancel
            </ActionsButton>
          </ActionsGroup>
        </Actions>
      </Block>}
      <UpdateDispositionModel ref={refModel} propertyId={params.propertyId} />
      <Dialog
        opened={alertOpened}
        onBackdropClick={() => setAlertOpened(false)}
        title="Nternow Error"
        content="This property is not paired to any lock or hub."
        buttons={
          <DialogButton onClick={() => {
            setAlertOpened(false)
            navTo('/property-manage');
          }}>Ok</DialogButton>
        }
      />
    </>
  )
}

