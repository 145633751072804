import { useQuery } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { HubServerRelay } from '../types/inputTypes/HubAccount'

export const useGetPropertyHubServers = (id?: string) => {
  return useQuery<HubServerRelay>(
    {
      queryKey: ['useGetPropertyHubServers', id],
      refetchOnWindowFocus: false,
      retry: 0,
      queryFn: async () => {
        if (!id) {
          return Promise.resolve(null)
        }
        return mobileService.get(`/properties/${id}/hub-servers`).then(item => item.data)
      }
    })
}