import { useMutation } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'

export const useMutationSaveCoordinates = () => {
  return useMutation(
    {
      mutationFn: ({ id, latitude, longitude }: { id: number, latitude: number, longitude: number }) => {
        return mobileService.patch(`/properties/${id}/coordinates`, {
          latitude,
          longitude
        })
      },
    }
  )
}