import React, { useState } from 'react'
import { Block, Button, Link, List, ListInput } from 'konsta/react'
import NternowLogo from '../../assets/logo-dark.svg'
import { MdArrowBack } from 'react-icons/md'
import { useForm, Controller } from 'react-hook-form'
import { goBack, navTo } from '../../browserHistory'
import { useAppNNConfig } from '../../store/appNNConfig'
import { HELP_CONTACT } from '../../constants/common'
import { useMutationLoginNternow } from '../../hooks/useMutationLoginNternow' // Import react-hook-form

export function LoginToNN() {
  const { setAppConfig, redirectUrl } = useAppNNConfig()
  const [errorMsg, setErrorMsg] = useState<string>()
  const { mutateAsync, isPending } = useMutationLoginNternow()
  const { control, handleSubmit } = useForm() // Initialize useForm

  const loginAction = (data: any) => {
    // Use the form data
    const { username, passwordX } = data

    mutateAsync({
      username, password: passwordX
    })
      .then((item) => {
        setAppConfig({
          token: item.data.token,
          hubAccountId: item.data.customerId,
          isAdmin: item.data.isAdmin,
          builderId: item.data.builderId
        })
        navTo(redirectUrl || '/activate-property')
      })
      .catch((e) => {
        setErrorMsg('Wrong username or password')
      })
  }

  const handleHelp = () => {
    window.open(HELP_CONTACT, '_self'); // Open help page in new tab
  };

  return (
    <Block className='justify-center items-center max-w-sm m-auto mt-32'>
      <NternowLogo className='text-center w-48 m-auto mb-5' />
      <p className='text-primary text-2xl font-bold text-center m-auto'>Login to Nternow</p>
      <p className="text-sm text-center m-auto italic mt-2">Please use your NterNow dashboard credentials to login, these are different than your hub credentials.</p>
      <form autoComplete='off' onSubmit={handleSubmit(loginAction)}>
        {' '}
        {/* Add form and handleSubmit */}
        <List>
          <Controller
            control={control}
            name='username'
            render={({ field }) => (
              <ListInput
                label='Username'
                type='text'
                required
                autoComplete={'off'}
                placeholder='Username'
                {...field} // Spread the field props
              />
            )}
          />
          <Controller
            control={control}
            name='passwordX'
            render={({ field }) => (
              <ListInput
                label='Password'
                type='password'
                required
                autoComplete={'new-password'}
                placeholder='Your password'
                {...field} // Spread the field props
              />
            )}
          />
          {!isPending && <label className="m-4 pt-2 text-red-500 text-sm">{errorMsg}</label>}
        </List>
        <Button large disabled={isPending}>
          {' '}
          {/* Change to type submit */}
          {isPending ? 'Logging in...' : 'Login'}
        </Button>
        <div className="flex justify-between gap-2 text-center mt-5">
          <Link onClick={() => goBack()}> <MdArrowBack /> Go back</Link>
          <Link onClick={handleHelp}>Need Help?</Link> {/* Help link added */}
        </div>
      </form>
    </Block>
  )
}
