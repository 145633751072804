import { useEffect, useState } from 'react'

export const useGeoLocation = (request?: boolean) => {
  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const requestLocation = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation(position);
        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    if (request) {
      requestLocation();
    }
  }, [])

  return {
    location,
    error,
    loading,
    requestLocation,
  };
}