import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type AppConfigData = {
  token?: string
  deviceId?: string
  hubAccountId?: string
  redirectUrl?: string
  isAdmin?: boolean
  builderId?: number
}

type AppConfigMethod = {
  setAppConfig: (data: Partial<AppConfigData>) => void
  resetAppConfig: () => void
}

type AppConfig = AppConfigData & AppConfigMethod

export const useAppNNConfig = create<AppConfig>()(
  persist(
    (set) => ({
      setAppConfig: (data) => {
        set((state) => ({ ...state, ...data }))
      },
      resetAppConfig: () => {
        set((state) => ({
          token: undefined,
          deviceId: undefined,
          hubAccountId: undefined,
          redirectUrl: undefined,
          loginType: undefined,
          hubServers: undefined,
          isAdmin: undefined,
          builderId: undefined
        }))
      }
    }),
    {
      name: 'app-nn-config'
    }
  )
)
