import { useEffect, useRef, useState } from 'react'
import Socket from '../services/socket'
import { CommandEnum, ConnectStatus, EzloResponseId } from '../constants/socket'
import { HubServerRelay } from '../types/inputTypes/HubAccount'
import { buildMessage, formatMessage } from './helper'
import { EventEmitter } from 'events'

const initMessage = (MMSAuth: string, MMSAuthSig: string) => ({
  method: 'loginUserMios',
  id: 'loginUser',
  params: {
    MMSAuth,
    MMSAuthSig
  }
})

const buildRegisterMessage = (id: string) => ({
  method: 'register',
  id: 'register',
  jsonrpc: '2.0',
  params: { serial: id }
})

const BROAD_CAST_MESSAGES_EVENT = 'BroadcastMessages'

export const useSocketIo = () => {
  const [connectStatus, setConnectStatus] = useState<ConnectStatus>(ConnectStatus.NotConnect)
  const socket = useRef<Socket>(Socket.newInstance())
  const eventEmitter = useRef<EventEmitter>(new EventEmitter())
  const [broadcastMessages, setBroadcastMessages] = useState<any[]>([])
  const triggerEvent = useRef<any>({})

  useEffect(() => () => socket.current.close(), [])

  // TODO: For sending debug from console
  useEffect(() => {
    ;(window as any).sendEvent = (data: any) => {
      const newData = {
        timestamp: new Date(),
        message: formatMessage(data),
        data
      }
      eventEmitter.current.emit(BROAD_CAST_MESSAGES_EVENT, newData)
      setBroadcastMessages((currentData) => [newData, ...currentData])
    }
  }, [])

  return {
    connectStatus,
    broadcastMessages,
    sendMessage: (command: CommandEnum, params = {}, id?: string, func?: (data: any) => void) => {
      // if (connectStatus !== ConnectStatus.Connected) {
      //   return
      // }
      if (id && func) {
        triggerEvent.current[id] = func
      }
      socket.current.send(buildMessage(command, params, id))
    },
    onBroadcast: (callback: (data: any) => void) => {
      eventEmitter.current.on(BROAD_CAST_MESSAGES_EVENT, callback)

      return () => eventEmitter.current.off(BROAD_CAST_MESSAGES_EVENT, callback)
    },
    disconnect: () => {
      socket.current.close()
      setConnectStatus(ConnectStatus.NotConnect)
    },
    init: (hubServer: HubServerRelay) => {
      setConnectStatus(ConnectStatus.Connecting)
      socket.current.init(hubServer.serverRelay)
      socket.current.on((data: any) => {
        console.log('Receive message', data)
        switch (data.id) {
          case EzloResponseId.LoginUser:
            socket.current.send(buildRegisterMessage(hubServer.hubId))
            break
          case EzloResponseId.Register:
            if (data.error !== null) {
              setConnectStatus(ConnectStatus.Failed)
              console.log(`Connect failed: ${data.error.data}`)
              break
            }
            setConnectStatus(ConnectStatus.Connected)
            console.log('Connected successfully', { variant: 'success' })
            break
          case EzloResponseId.UIBroadcast:
            console.log('UIBroadcast', data)
            const newData = {
              timestamp: new Date(),
              message: formatMessage(data),
              data
            }
            // if (data.result.event === 'include_finished_timeout') {
            //   eventEmitter.current.emit(BROAD_CAST_MESSAGES_EVENT, {"timestamp":"2023-10-10T14:14:30.065Z","data":{"id":"ui_broadcast","msg_id":"65255c45222a4816ffa1c69c","msg_subclass":"hub.extensions.plugin.ui_broadcast","result":{"error":{"code":-4,"data":"hub.gateway.zwave.err.time","details":{"node_id":"0","step":"0"}},"event":"s2_request_device_specific_key","plugin":"zwave"}}})
            // }
            eventEmitter.current.emit(BROAD_CAST_MESSAGES_EVENT, newData)
            setBroadcastMessages((currentData) => [newData, ...currentData])
            break
          default:
            const fnc = triggerEvent.current[data.id]
            if (fnc) {
              fnc(data)
              delete triggerEvent.current[data.id]
            }
          // case CommandEnum.HubDevicesList:
          //     // eslint-disable-next-line no-case-declarations
          //     const device = extractLockDevice(data.result.devices);
          //     setLockDevices(device);
          //     if (device) {
          //         loadDeviceCode([device.id]);
          //     }
          //     break;
          // case CommandEnum.HubItemsList:
          //     setLockProperties(extractDeviceProperties(data.result.items));
          //     setDeviceProperties(data.result.items);
          //     break;
          // case CommandEnum.HubNetworkGet:
          //     setNetworkInfo(extractNetworkMobile(data.result.interfaces));
          //     break;
          // default:
          //     setResponseData(data);
          //     break;
        }
      })

      // Auth
      socket.current.onConnected(() => {
        console.log('server connected')
        socket.current.send(initMessage(hubServer.identity, hubServer.identitySignature))
      })

      socket.current.onClosed(() => {
        console.log('server closed')
        setConnectStatus((status) => (status === ConnectStatus.Failed ? status : ConnectStatus.Connecting))
      })
    }
  }
}
