import { Block, Link, Navbar, Page, Popup } from 'konsta/react'
import React from 'react'
import { PopupCustom } from '../../../components/PopupCustom'

interface Props {
  open: boolean
  close?: () => void
}

export function ZwaveReset({ close, open }: Props) {
  const handleClose = () => {
    close && close()
  }

  return (
    <>
      {' '}
      <PopupCustom opened={open} onBackdropClick={() => handleClose()}>
        <Page>
          <Navbar
            title='Zwave reset'
            right={
              <Link navbar onClick={() => handleClose()}>
                x
              </Link>
            }
          />
          <Block className='space-y-4'>
            {open && (
              <>
                <p className='text-gray-800'>
                  Z-wave reset command has been sent.
                  <br />
                  Please see logs for details below.
                </p>
              </>
            )}
          </Block>
        </Page>
      </PopupCustom>
    </>
  )
}
