import React from 'react'
import { MdLock } from 'react-icons/md'
import { Block, Button, List, ListInput } from 'konsta/react'
import SubmitSerialImage from '../../../assets/submit_serial_number.png'
import { Controller, useForm } from 'react-hook-form'
import { useAttachHub } from '../../../hooks/useAttachHub'
import { GlobalLoading } from '../../../components/GlobalLoading'
import { useErrorDialog } from '../../../components/IErrorDialog'
import { useMutationSaveCoordinates } from '../../../hooks/useMutationSaveCoordinates'
import { useGeoLocation } from '../../../hooks/useGeoLocation'
import { BsLightningCharge } from 'react-icons/bs'
import { appShareStore } from '../../../store/appShareStore'

interface Props {
  nextStep: () => void;
  propertyId: number;
}

export const SubmitSerialNumber = ({ propertyId, nextStep }: Props) => {
  const { deviceId, resetAppShareConfig } = appShareStore()
  const { control, handleSubmit } = useForm({
    defaultValues: {
      serial_number: deviceId
    }
  }) // Initialize useForm
  const { mutateAsync, isPending } = useAttachHub();
  const { location } = useGeoLocation(true);
  const { mutateAsync: saveCoordinate, isPending: isPendingCoordinate } = useMutationSaveCoordinates();
  const { showErrorDialog } = useErrorDialog();

  const handleAttach = async (data: any) => {
    resetAppShareConfig();
    try {
      const res = await mutateAsync({ id: propertyId, lockSerialNumber: data.serial_number });
      if (res.data.error) {
        showErrorDialog(res.data.message);
      } else {
        if (!location) {
          showErrorDialog('Location not turn on');
          return;
        }
        await saveCoordinate({
          id: propertyId, latitude: location?.coords.latitude, longitude: location?.coords.longitude
        })
        nextStep();
      }
    } catch (e: any) {
      showErrorDialog(e.message);
    }
  }

  return (
    <>
      <GlobalLoading loading={isPending || isPendingCoordinate} text={isPendingCoordinate ? 'Saving coordinate' : isPending ? 'Activating ...' : ''}/>
      <Block className="mb-safe">
        <div className="container">
          <img src={SubmitSerialImage} className="m-auto h-[250px] p-3 mb-5" alt="Submuit serial number" />
          <h1 className="text-lg m-2 text-center font-bold">Please Enter the device 8-digit Serial Number found on the bottom of the Smart Home unit</h1>
          <form onSubmit={handleSubmit(handleAttach)}>
            <List strongIos insetIos>
            <Controller
              control={control}
              name='serial_number'
              render={({ field }) => (
                <ListInput
                  // label='Serial number'
                  floatingLabel={false}
                  media={<MdLock />}
                  type='number'
                  required
                  autoComplete={'off'}
                  placeholder='Serial number'
                  {...field} // Spread the field props
                />
              )}
            />
            </List>
            <Button large className="mt-10 flex gap-1">
              Activate <BsLightningCharge/>
            </Button>
          </form>
        </div>
      </Block>
    </>
  )
}
